import { getOrigin } from "@/helpers";
import { makeBlobUrlFromBase64 } from "@/helpers/downloadLossForm";
import { getChangeRequestTableRowActions } from "@/helpers/getChangeRequestTableRowActions";
import {
  changeRequestStatus,
  customBulkMenuOptions
} from "@/helpers/selectOptions";
import { IStorageFormBase } from "@/helpers/storageFormBaseInterface";
import { authMapGetters } from "@/store/modules/auth";
import { IChangeRequestUpdateStatusPayload } from "@/store/types";
import { capitalize } from "lodash";
import Vue from "vue";
interface IListPropertyChangeRequestsData {
  loading: boolean;
  errorMessage: string;
  successMessage: string;
  showExporter: boolean;
  customBulkMenuActions: { title: string; disabled: boolean }[];
  selectedDocuments: IStorageFormBase[];
  status: string;
  filters: any[];
  showChart: string;
  queryOverride: any;
  changeRequestId: string;
  key: number;
  loadingText: string;
  reserveSelection?: boolean;
  pageKey: string;
  exportModalKey: string;
  activityRoute: string;
  changeRequestRoute: string;
  deleteModalKey: string;
}
export default Vue.extend({
  data(): IListPropertyChangeRequestsData {
    return {
      loading: false,
      successMessage: "",
      errorMessage: "",
      showExporter: false,
      customBulkMenuActions: customBulkMenuOptions,
      selectedDocuments: [],
      status: "",
      filters: [
        {
          field: "createdOn",
          title: "Created On",
          isDate: true
        },
        {
          field: "submittedOn",
          title: "Submitted On",
          isDate: true
        },
        {
          field: "status",
          title: "Status",
          options: changeRequestStatus
        },
        {
          field: "printed",
          title: "Printed",
          options: [
            { label: "Yes", value: "true" },
            { label: "No", value: "false" }
          ]
        }
      ],
      showChart: "",
      queryOverride: { deleted: false, isLegacy: false },
      changeRequestId: "",
      key: 0,
      loadingText: "",
      reserveSelection: true,
      pageKey: "",
      exportModalKey: "",
      activityRoute: "",
      changeRequestRoute: "",
      deleteModalKey: ""
    };
  },
  mounted() {
    if (
      this.$getCurrentUser &&
      this.$getCurrentUser.role !== "admin" &&
      this.$getCurrentUser.role !== "underwriting"
    ) {
      this.customBulkMenuActions = [];
    }
  },
  methods: {
    async onToolbarItemClick(event: string) {
      switch (event) {
        case "create":
          this.createChangeRequest();
          break;
        case "view-chart":
          this.showChart = event;
          break;
        case "view-list":
          this.showChart = "";
          break;
        case "change-requests":
          this.queryOverride.deleted = false;
          this.queryOverride.isLegacy = false;
          this.key++;
          break;
        case "legacy-change-requests":
          this.queryOverride.isLegacy = true;
          this.key++;
          break;
        case "deleted-change-requests":
          this.queryOverride = {
            ...this.queryOverride,
            deleted: true,
            status__in: []
          };
          this.key++;
          break;
      }
    },
    async printCallback(
      changeRequestIds: string | Array<string>,
      callback: (data: Record<string, any>) => any,
      changeRequestType: "property" | "billing" | "pay plan",
      policyPrefix?: string
    ) {
      const newTab = window.open(
        `${getOrigin()}/loading-preview/noMessage`,
        "_blank"
      );
      try {
        this.loading = true;
        this.loadingText = `Printing ${changeRequestType} change request...`;
        const data = {
          _ids: Array.isArray(changeRequestIds)
            ? changeRequestIds
            : [changeRequestIds],
          site: "ATLAS",
          policyPrefix
        };
        const fileURL = await callback(data);
        if (fileURL) {
          const url = makeBlobUrlFromBase64(fileURL.document);
          if (newTab) {
            newTab.location.href = url;
          }
          this.$appNotifySuccess(
            `${capitalize(
              changeRequestType
            )} change request printed successfully.`
          );
        } else {
          newTab?.close();
          this.$appNotifyError("Error printing request. Try again later.");
        }
      } catch (error) {
        newTab?.close();
        this.errorMessage = error.message;
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
      }
    },
    getChart(
      chartId:
        | "billing-change-status"
        | "pay-plan-change-status"
        | "policy-change-status"
    ): string {
      const { charts = [] } = this.$store.state.auth["initData"] || {};
      return charts.find((chart: any) => chart.id === chartId);
    },
    async updateStatus(
      status: any,
      callback: (data: IChangeRequestUpdateStatusPayload) => Promise<any>
    ) {
      const ids = this.selectedDocuments.map((doc: any) => doc._id);
      await callback({
        ids: ids,
        status: status
      });
      this.$appNotifySuccess("Status changed");
    },
    tableActionHandler(action: string) {
      switch (action) {
        case `Export ${this.pageKey} Change Requests`:
          this.$modal.show(this.exportModalKey);
          break;
        case "All Activities":
          this.$router.push(this.activityRoute).catch(() => {});
          break;
      }
    },
    async createChangeRequest(): Promise<any> {
      this.$router.push(`/${this.changeRequestRoute}/new`).catch(() => {});
    },
    async viewChangeRequest(dataReceived: any): Promise<any> {
      const { _id, isLegacy } = dataReceived;
      if (isLegacy) {
        this.$router
          .push(`${this.changeRequestRoute}/${_id}/legacy`)
          .catch(() => {});
      } else {
        this.$router.push(`${this.changeRequestRoute}/${_id}`).catch(() => {});
      }
    },
    async deleteCallback(changeRequestId: string) {
      try {
        this.changeRequestId = changeRequestId;
        this.$modal.show(this.deleteModalKey);
      } catch (error) {
        this.errorMessage = error.message;
        this.$bugSnagClient.notify(error);
      }
    }
  },
  computed: {
    ...authMapGetters(["getCurrentUser"]),
    topButtonSecondaryItems(): {
      text: string;
      key: string;
      subItems?: { title: string; command: string }[];
    } | null {
      const data = !this.showChart
        ? {
            title: "View Report",
            command: "view-chart"
          }
        : {
            title: "View List",
            command: "view-list"
          };
      const actions = this.$isCurrentUserAdmin ? [data] : [];

      const { deleted, isLegacy } = this.queryOverride;
      if (!this.showChart) {
        if (!deleted) {
          actions.push({
            title: "Trash Bin",
            command: "deleted-change-requests"
          });
        }
        if (deleted || isLegacy) {
          actions.push({
            title: "Change Requests",
            command: "change-requests"
          });
        }
        if (!isLegacy) {
          actions.push({
            title: "Legacy Change Requests",
            command: "legacy-change-requests"
          });
        }
      }
      if (
        this.$isCurrentUserAdmin ||
        this.$isCurrentUserUnderwriter ||
        this.$isCurrentUserClaims ||
        this.$isCurrentUserBilling ||
        this.$isCurrentUserDataEntry
      ) {
        return {
          text: "Actions",
          key: "actions",
          subItems: actions
        };
      }
      return null;
    },
    bulkMenuActionsData(): { title: string; disabled: boolean }[] {
      const bulkMenuActions = [{ title: "Print Selected", disabled: false }];
      if (
        this.getCurrentUser &&
        (this.getCurrentUser.role === "admin" ||
          this.getCurrentUser.role === "underwriting")
      ) {
        const { deleted } = this.queryOverride;
        if (deleted) {
          bulkMenuActions.push({
            title: "Restore Selected",
            disabled: false
          });
        } else {
          bulkMenuActions.push({
            title: "Delete Selected",
            disabled: false
          });
        }
      }
      return bulkMenuActions;
    },
    tableRowActions(): any {
      return (
        getChangeRequestTableRowActions(
          this.$getCurrentUser?.role,
          this.queryOverride
        ) || []
      );
    },
    getSelectedDocumentIds(): string[] {
      return this.selectedDocuments.map(
        (document: IStorageFormBase) => document._id
      );
    }
  }
});
