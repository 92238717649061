
import { billingChangeRequestMapState } from "@/store/modules/billingchangerequest";
import Vue from "vue";
import { authMapGetters } from "@/store/modules/auth";
export default Vue.extend({
  props: {
    row: {
      type: Object
    }
  },
  methods: {
    eventHandler(name: string) {
      this.$emit("emitEvent", { name, data: this.row });
    }
  },
  computed: {
    ...billingChangeRequestMapState(["makingApiRequest"]),
    ...authMapGetters(["getCurrentUser"])
  }
});
