export function getChangeRequestTableRowActions(
  role: string,
  queryOverride: any
): any[] {
  const tableRowActions = [
    {
      label: "View",
      value: "view",
      faIcon: "eye",
      show: (row: any) => {
        if (role === "admin") {
          return true;
        }
        if (row.status == "Submitted" || row.status == "Issued") {
          return true;
        }
        return false;
      }
    },
    { label: "Print", value: "print", faIcon: "print" }
  ];
  const { deleted } = queryOverride;
  const userAllowed =
    role === "admin" ||
    role === "underwriting" ||
    role === "claims" ||
    role === "billing" ||
    role === "dataEntry";
  if (deleted) {
    tableRowActions.push({
      label: "Restore",
      value: "restore",
      faIcon: "trash-restore",
      show: () => {
        return userAllowed;
      }
    });
  } else {
    tableRowActions.push({
      label: "Delete",
      value: "delete",
      faIcon: "trash-alt",
      show: () => {
        return userAllowed;
      }
    });
    tableRowActions.unshift({
      label: "Edit",
      value: "edit",
      faIcon: "edit",
      show: (row: any) => {
        if (userAllowed && row.status == "Issued") {
          return false;
        }
        if (
          !userAllowed &&
          (row.status == "Submitted" || row.status == "Issued")
        ) {
          return false;
        }
        return true;
      }
    });
  }
  return tableRowActions;
}
